import InvoiceDocumentImage from '@landing/assets/solutions/dashboard/invoice-document.png';
import DashboardInvoicesImage from '@landing/assets/solutions/dashboard/invoices.png';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function InvoicesVisual() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      className='relative md:absolute md:right-0 z-30 -ml-[12%] mt-8 pb-6 md:mt-16'
      // className='relative z-30 -ml-[12%] mt-8 pb-6 md:mt-16'
      initial='hidden'
      animate='show'
      variants={slideContentVariants}
      custom={direction}
    >
      <div className='relative min-w-[600px] max-w-[775px] w-full ml-auto'>
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={InvoiceDocumentImage}
          alt=''
          className='absolute -z-10 -top-[15.21394612%] right-0 w-[53.87596899%] max-w-[417px]'
        />
        <div className='flex w-full'>
          <MotionImage
            variants={slideContentItemVariants}
            custom={direction}
            src={DashboardInvoicesImage}
            alt=''
            className='min-w-0 max-w-[631px] basis-[631px] ml-auto mt-[3.5%] w-full'
          />
          <div className='basis-[144px] max-w-[144px] w-[22.82091918%] h-32' />
        </div>
      </div>
    </motion.div>
  );
}
