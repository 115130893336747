import React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from '../utils/cn';

export const appBadgeVariants = cva(
  'inline-flex items-center text-xs leading-normal font-normal',
  {
    variants: {
      variant: {
        default: 'ring-1 ring-inset',
        flat: '',
      },
      size: {
        sm: 'px-1.5',
        md: 'px-4',
      },
      pill: {
        true: 'rounded-full',
        false: 'rounded-md',
      },
      dot: {
        true: 'gap-x-1.5',
      },
      color: {
        default:
          'bg-[#F9FAFB] text-slate-600 ring-slate-500/10 [&>svg]:fill-gray-400',
        primary:
          'bg-[#EFF6FF] text-[#0A3FD9] ring-[#2C61F9]/10 [&>svg]:fill-blue-500',
        success:
          'bg-green-50 text-green-700 ring-green-600/20 [&>svg]:fill-green-500',
        error: 'bg-red-50 text-red-700 ring-red-600/10 [&>svg]:fill-red-500',
        warning:
          'bg-yellow-50 text-yellow-700 ring-yellow-700/10 [&>svg]:fill-yellow-500',
        indigo:
          'bg-indigo-50 text-indigo-700 ring-indigo-700/10 [&>svg]:fill-indigo-500',
        purple:
          'bg-purple-50 text-purple-700 ring-purple-700/10 [&>svg]:fill-purple-500',
        pink: 'bg-pink-50 text-pink-700 ring-pink-700/10 [&>svg]:fill-pink-500',
      },
    },
    compoundVariants: [
      { variant: 'flat', color: 'default', className: 'bg-gray-100' },
      { variant: 'flat', color: 'primary', className: 'bg-blue-100' },
      { variant: 'flat', color: 'success', className: 'bg-green-100' },
      { variant: 'flat', color: 'error', className: 'bg-red-100' },
      { variant: 'flat', color: 'warning', className: 'bg-yellow-100' },
      { variant: 'flat', color: 'indigo', className: 'bg-indigo-100' },
      { variant: 'flat', color: 'purple', className: 'bg-purple-100' },
      { variant: 'flat', color: 'pink', className: 'bg-pink-100' },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'md',
      pill: false,
      color: 'default',
    },
  },
);

export type AppBadgeProps = React.ComponentPropsWithoutRef<'span'> &
  VariantProps<typeof appBadgeVariants>;

export type AppBadgeColor = NonNullable<AppBadgeProps['color']>;

export const AppBadge = React.forwardRef<
  React.ElementRef<'span'>,
  AppBadgeProps
>(({ variant, size, pill, dot, color, className, children, ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      appBadgeVariants({ variant, size, pill, dot, color }),
      className,
    )}
    {...props}
  >
    {dot && (
      <svg className='h-1.5 w-1.5' viewBox='0 0 6 6' aria-hidden='true'>
        <circle cx={3} cy={3} r={3} />
      </svg>
    )}
    {children}
  </span>
));
