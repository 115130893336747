import { Variants, motion } from 'framer-motion';
import { Ellipse } from './Ellipse';
import { RotatingLine } from './RotatingLine';

import YenIcon from '@landing/assets/blue-yen-rounded-white-border.svg?url';
import PersonPfp1Image from '@landing/assets/person-pfp-1.png';
import PersonPfp2Image from '@landing/assets/person-pfp-2.png';
import SphereLogo from '@landing/assets/sphere-logomark.svg?url';
import UsdIcon from '@landing/assets/blue-usd-rounded-white-border.svg?url';
import EuroIcon from '@landing/assets/blue-euro-rounded-white-border.svg?url';

import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';

const containerVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      bounce: 0.25,
      duration: 0.5,
      staggerChildren: 0.25,
    },
  },
};
const childVariants: Variants = {
  initial: { opacity: 0, scale: 0.25, origin: 'center' },
  animate: {
    opacity: 1,
    scale: 1,
    origin: 'center',
    transition: { bounce: 0.25, duration: 0.4 },
  },
};

export function MultipartyPayments() {
  return (
    <div className='w-full h-full relative'>
      <motion.div
        className='absolute w-[90%] left-[25%] text-brand'
        variants={containerVariants}
        initial='initial'
        whileInView='animate'
        viewport={{ once: true }}
        transition={{
          type: 'spring',
          bounce: 0.25,
          duration: 0.75,
          delay: 0.5,
        }}
      >
        {/* <div className='absolute w-full left-[0%] border text-brand'> */}
        <motion.div
          variants={childVariants}
          className='w-full h-auto aspect-square origin-center'
        >
          <Ellipse
            className='aspect-square w-full h-full'
            animate={{
              rotate: '-360deg',
            }}
            transition={{
              repeatType: 'loop',
              duration: 50,
              ease: 'linear',
              repeat: Number.POSITIVE_INFINITY,
              delay: -1,
            }}
          />
          <RotatingLine
            animate={{ rotate: ['60deg', '-300deg'] }}
            className='bg-transparent'
          >
            <RotatingLine.Tip
              asChild
              className='size-10'
              animate={{ rotate: ['-60deg', '300deg'] }}
            >
              <MotionImage src={PersonPfp2Image} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
        </motion.div>
        <motion.div
          variants={childVariants}
          style={{ translateX: '-50%', translateY: '-50%' }}
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[75%] h-auto aspect-square origin-center '
        >
          <Ellipse
            className='aspect-square w-full h-full'
            animate={{
              rotate: '-360deg',
            }}
            transition={{
              repeatType: 'loop',
              duration: 50,
              ease: 'linear',
              repeat: Number.POSITIVE_INFINITY,
            }}
          />
          <RotatingLine animate={{ rotate: ['60deg', '-300deg'] }}>
            <RotatingLine.Tip
              asChild
              className='size-6'
              animate={{ rotate: ['-60deg', '300deg'] }}
            >
              <MotionImage src={UsdIcon} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
          <RotatingLine
            animate={{ rotate: ['0deg', '-360deg'] }}
            className='bg-transparent'
          >
            <RotatingLine.Tip
              asChild
              className='size-10'
              animate={{ rotate: ['15deg', '375deg'] }}
            >
              <MotionImage src={PersonPfp1Image} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
        </motion.div>

        <motion.div
          variants={childVariants}
          style={{ translateX: '-50%', translateY: '-50%' }}
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-auto aspect-square origin-center w-[50%]'
        >
          <Ellipse
            className='aspect-square w-full h-full'
            animate={{
              rotate: '-360deg',
            }}
            transition={{
              repeatType: 'loop',
              duration: 50,
              ease: 'linear',
              repeat: Number.POSITIVE_INFINITY,
            }}
          />

          <RotatingLine animate={{ rotate: ['0deg', '-360deg'] }}>
            <RotatingLine.Tip
              asChild
              className='size-6'
              animate={{ rotate: ['0deg', '360deg'] }}
            >
              <MotionImage src={EuroIcon} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
          <RotatingLine
            className='w-1/4'
            animate={{ rotate: ['105deg', '-255deg'] }}
          >
            <RotatingLine.Tip
              asChild
              className='size-6'
              animate={{ rotate: ['-105deg', '255deg'] }}
            >
              <MotionImage src={UsdIcon} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
          <RotatingLine
            className='w-1/2 bg-transparent'
            animate={{ rotate: ['105deg', '-255deg'] }}
          >
            <RotatingLine.Tip
              asChild
              className='size-6'
              animate={{ rotate: ['-105deg', '255deg'] }}
            >
              <MotionImage src={YenIcon} alt='' />
            </RotatingLine.Tip>
          </RotatingLine>
        </motion.div>

        <motion.div
          variants={childVariants}
          style={{ translateX: '-50%', translateY: '-50%' }}
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-auto aspect-square origin-center outline outline-4 outline-white rounded-full shadow-lg'
        >
          <MotionImage src={SphereLogo} alt='' />
        </motion.div>
      </motion.div>
    </div>
  );
}
