import { motion } from 'framer-motion';

import { cn } from '@/common/utils/cn';
import { Variants } from 'framer-motion';
import { ForwardedRef, forwardRef } from 'react';

export const containerVariants: Variants = {
  hidden: {
    y: 100,
  },
  show: {
    y: 0,
    transition: {
      staggerChildren: 0.25,
    },
  },
};

export const childVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.5,
      bounce: 0.1,
    },
  },
};

interface PaymentBlockedProps {
  className?: string;
}
export function PaymentBlocked({ className }: PaymentBlockedProps) {
  return (
    <motion.div
      variants={containerVariants}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true }}
      className={cn(
        'flex flex-col items-center gap-y-2 px-6 *:w-full',
        className,
      )}
    >
      <motion.div variants={childVariants}>
        <PaymentBlockedSvg
          address='198.51.100.1'
          amount='$300.12 USD'
          reason='Sanctioned IP address'
        />
      </motion.div>
      <motion.div variants={childVariants}>
        <PaymentBlockedSvg
          address='169.170.90.7'
          amount='$15.45 USD'
          reason='Sanctioned IP address'
        />
      </motion.div>
      <motion.div variants={childVariants}>
        <PaymentBlockedSvg
          address='192.168.1.100'
          amount='$120.87 USD'
          reason='Sanctioned IP address'
        />
      </motion.div>
      <motion.div variants={childVariants}>
        <PaymentBlockedSvg
          address='XoAZ...2m32'
          amount='21.40 SOL'
          reason='Sanctioned wallet address'
        />
      </motion.div>
    </motion.div>
  );
}

const PaymentBlockedSvg = forwardRef(function PaymentBlockedSvg(
  {
    reason,
    address,
    amount,
  }: {
    reason: string;
    address: string;
    amount: string;
  },
  ref: ForwardedRef<SVGSVGElement>,
) {
  return (
    <motion.svg
      ref={ref}
      width='325'
      height='67'
      viewBox='0 0 325 67'
      fill='none'
      className='w-full h-auto'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.455157'
        y='0.455157'
        width='323.64'
        height='66.0173'
        rx='5.75634'
        fill='white'
      />
      <rect
        x='0.455157'
        y='0.455157'
        width='323.64'
        height='66.0173'
        rx='5.75634'
        stroke='#E4EBFF'
        strokeWidth='0.910315'
      />
      <rect
        x='12.4229'
        y='19.8086'
        width='27.3088'
        height='27.3088'
        rx='13.6544'
        fill='#FFE4E4'
      />
      <rect
        x='12.4229'
        y='19.8086'
        width='27.3088'
        height='27.3088'
        rx='13.6544'
        stroke='#FFE4E4'
        strokeOpacity='0.4'
        strokeWidth='4.55147'
      />
      <circle cx='26.166' cy='33.5518' r='13.7432' fill='#FFE4E4' />
      <path
        d='M26.3982 29.4564V34.8915M26.3982 37.2208H26.4059M18.6338 30.6387V36.0385C18.6338 36.2284 18.6338 36.3233 18.6552 36.4127C18.6743 36.4919 18.7056 36.5676 18.7482 36.6371C18.7962 36.7155 18.8634 36.7826 18.9977 36.9169L22.8198 40.7391C22.9541 40.8734 23.0213 40.9405 23.0996 40.9885C23.1691 41.0311 23.2448 41.0625 23.3241 41.0815C23.4134 41.103 23.5084 41.103 23.6983 41.103H29.098C29.2879 41.103 29.3829 41.103 29.4723 41.0815C29.5515 41.0625 29.6272 41.0311 29.6967 40.9885C29.775 40.9405 29.8422 40.8734 29.9765 40.7391L33.7987 36.9169C33.933 36.7826 34.0001 36.7155 34.0481 36.6371C34.0907 36.5676 34.1221 36.4919 34.1411 36.4127C34.1625 36.3233 34.1625 36.2284 34.1625 36.0385V30.6387C34.1625 30.4488 34.1625 30.3539 34.1411 30.2645C34.1221 30.1853 34.0907 30.1095 34.0481 30.0401C34.0001 29.9617 33.933 29.8946 33.7987 29.7603L29.9765 25.9381C29.8422 25.8038 29.775 25.7367 29.6967 25.6886C29.6272 25.6461 29.5515 25.6147 29.4723 25.5957C29.3829 25.5742 29.2879 25.5742 29.098 25.5742H23.6983C23.5084 25.5742 23.4134 25.5742 23.3241 25.5957C23.2448 25.6147 23.1691 25.6461 23.0996 25.6886C23.0213 25.7367 22.9541 25.8038 22.8198 25.9381L18.9977 29.7603C18.8634 29.8946 18.7962 29.9617 18.7482 30.0401C18.7056 30.1095 18.6743 30.1853 18.6552 30.2645C18.6338 30.3539 18.6338 30.4488 18.6338 30.6387Z'
        stroke='#E3424D'
        strokeWidth='1.16466'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <text
        fill='black'
        xmlSpace='preserve'
        style={{ whiteSpace: 'pre' }}
        fontSize='10.8701'
        fontWeight='500'
        letterSpacing='0px'
      >
        <tspan x='60.8701' y='28.882'>
          {`Payment Blocked: ${reason}`}
        </tspan>
      </text>
      <text
        fill='#7387A4'
        xmlSpace='preserve'
        style={{ whiteSpace: 'pre' }}
        fontSize='10.8701'
        letterSpacing='0px'
      >
        <tspan x='60.8701' y='45.8127'>
          {address}
        </tspan>
      </text>
      <circle cx='133.469' cy='42.2927' r='1.94109' fill='#D9D9D9' />
      <text
        fill='#7387A4'
        xmlSpace='preserve'
        style={{ whiteSpace: 'pre' }}
        fontSize='10.8324'
        fontWeight='500'
        letterSpacing='-0.02em'
      >
        <tspan x='140.069' y='46.0139'>
          {amount}
        </tspan>
      </text>
    </motion.svg>
  );
});
