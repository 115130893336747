import CheckCircleIcon from '@/common/icons/CheckCircleIcon';
import { cn } from '@/common/utils/cn';
import { FC, ReactNode } from 'react';

interface InfoBulletListRootProps {
  children: ReactNode;
  className?: string;
}

const InfoBulletListRoot: FC<InfoBulletListRootProps> = ({
  children,
  className,
}) => {
  return (
    <ul
      className={cn(
        'flex flex-col gap-y-2 text-subtle text-sm leading-5',
        className,
      )}
    >
      {children}
    </ul>
  );
};

interface InfoBulletItemProps {
  children: ReactNode;
  className?: string;
}

const InfoBulletItem: FC<InfoBulletItemProps> = ({ children, className }) => {
  return (
    <li className={cn('inline-flex items-center gap-2', className)}>
      <CheckCircleIcon
        fill='currentColor'
        className='shrink-0 size-4 text-brand'
      />
      {children}
    </li>
  );
};

export const InfoBulletList = Object.assign(InfoBulletListRoot, {
  Item: InfoBulletItem,
});
