import { cn } from '@/common/utils/cn';
import React, { PropsWithChildren } from 'react';

interface GridRootProps {
  children: React.ReactNode;
  className?: string;
}

function GridRoot({ children, className }: GridRootProps) {
  return <div className={cn('flex w-full gap-4', className)}>{children}</div>;
}

function GridColumn({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn('grow shrink flex flex-col gap-4', className)}>
      {children}
    </div>
  );
}

export function GridCard({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        'isolate relative w-full bg-surface-3 px-5 py-[1.375rem] border border-outline rounded-[0.5625rem] shadow-sphere-soft overflow-hidden',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function CardTitle({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <h4 className={cn('relative mb-2 z-30', className)}>{children}</h4>;
}

export const Grid = Object.assign(GridRoot, {
  Column: GridColumn,
  Card: GridCard,
  CardTitle: CardTitle,
});
