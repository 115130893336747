import * as React from 'react';
import { motion } from 'framer-motion';

const SIZE = 360;

import { forwardRef } from 'react';

function EllipseFn(
  props: React.SVGProps<SVGSVGElement>,
  ref: React.Ref<SVGSVGElement>,
) {
  const strokeWidth = props.strokeWidth || 4;
  const radius = SIZE / 2 - Number(strokeWidth);
  const circumference = 2 * Math.PI * radius;

  const strokeDashArrayProportion = circumference / 100;

  return (
    <svg
      width={SIZE}
      height={SIZE}
      fill='none'
      stroke='currentColor'
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      strokeWidth={strokeWidth}
      vectorEffect='non-scaling-stroke'
      {...props}
      ref={ref}
    >
      <defs>
        <clipPath id='cutOffDash'>
          <circle
            cx={SIZE / 2}
            cy={SIZE / 2}
            r={radius}
            stroke='#FFFFFF'
            fill='#FFFFFF'
            // color='white'
            // vectorEffect='non-scaling-stroke'
          />
        </clipPath>
      </defs>

      <circle
        cx={SIZE / 2}
        cy={SIZE / 2}
        r={radius} // slightly smaller radius for better stroke alignment
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray={`${strokeDashArrayProportion / 2} ${
          strokeDashArrayProportion * 2
        }`}
        vectorEffect='non-scaling-stroke'
        style={{ clipPath: 'url(#cutOffDash)' }}
      />
    </svg>
  );
}

export const Ellipse = motion(forwardRef(EllipseFn));
