import DashboardSubscriptionsImage from '@landing/assets/solutions/dashboard/subscriptions.png';

import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';

import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function SubscriptionsVisual() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      className='relative z-30 max-md:pb-4 max-md:px-4 md:-ml-[12%]'
      initial='hidden'
      animate='show'
      variants={slideContentVariants}
      custom={direction}
    >
      <div className='relative min-w-[400px] max-w-[631px] mx-auto md:ml-auto md:mr-0 md:top-10'>
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={DashboardSubscriptionsImage}
          alt=''
          className='relative'
        />
      </div>
    </motion.div>
  );
}
