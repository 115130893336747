import DashboardPaymentLinksPhoneImage from '@landing/assets/solutions/dashboard/payment-links-phone.png';
import DashboardPaymentLinksImage from '@landing/assets/solutions/dashboard/payment-links.png';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function PaymentLinksVisual() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      className='md:absolute right-0 px-4 max-md:aspect-[351/340] md:-ml-[12%] md:mt-4 z-30'
      initial='hidden'
      animate='show'
      variants={slideContentVariants}
      custom={direction}
    >
      <div className='relative max-md:min-w-[391px] md:w-[671px] max-w-[671.37px] ml-auto'>
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={DashboardPaymentLinksImage}
          className='relative md:-left-[5.95796655%]'
          alt=''
        />
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={DashboardPaymentLinksPhoneImage}
          alt=''
          className='absolute w-[37.53%] h-auto -bottom-[62.89475788%] right-[22.5%] md:w-[34.7%] md:right-0'
        />
      </div>
    </motion.div>
  );
}
