'use client';
import { MotionDiv } from '@landing/components/Motion';

import * as paymentMethodsImages from '@/app/[lang]/(landing)/assets/payment-methods';

import Image from 'next/image';
import { Marquee } from '@landing/components/Marquee';

function balanceArray<T>(array: T[]): [T[], T[], T[]] {
  const length = array.length;
  const third = Math.floor(length / 3);
  const remainder = length % 3;

  const sizes = [third, third, third];

  switch (remainder) {
    case 1:
      sizes[1]++;
      break;
    case 2:
      sizes[0]++;
      sizes[2]++;
      break;
  }

  // Visual balance adjustment for larger arrays
  if (length > 10 && sizes[1] > sizes[0] + 1) {
    sizes[0]++;
    sizes[1]--;
  }

  return [
    array.slice(0, sizes[0]),
    array.slice(sizes[0], sizes[0] + sizes[1]),
    array.slice(sizes[0] + sizes[1]),
  ];
}
const paymentMethodsImagesArray = balanceArray(
  Object.values(paymentMethodsImages),
);

export function PaymentMethodsMarquee() {
  return (
    <MotionDiv
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className='space-y-6 w-full pt-6 pb-10 my-auto [mask-image:_linear-gradient(to_right,transparent,#000_20%,#000_80%,transparent_100%)]'
    >
      {paymentMethodsImagesArray.map((chunk, chunkIndex) => (
        <Marquee
          // biome-ignore lint/suspicious/noArrayIndexKey: index is the best option here
          key={chunkIndex}
          direction={chunkIndex === 1 ? 'left' : 'right'}
        >
          <Marquee.Container className='w-full'>
            <Marquee.Content speed={15}>
              {chunk.map((image, imageIndex) => (
                <Marquee.Item
                  // biome-ignore lint/suspicious/noArrayIndexKey: index is the best option here
                  key={imageIndex}
                  className='size-10 shrink-0'
                >
                  <Image
                    src={image}
                    alt='Payment method'
                    className='object-contain'
                  />
                </Marquee.Item>
              ))}
            </Marquee.Content>
          </Marquee.Container>
        </Marquee>
      ))}
    </MotionDiv>
  );
}
