import { cn } from '@/common/utils/cn';
import { HTMLMotionProps, MotionProps, motion } from 'framer-motion';
import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { AnimatedSlot } from '@/app/[lang]/(landing)/components/AnimatedSlot';

const Line = ({
  className,
  children,
  transition,
  ...props
}: HTMLMotionProps<'div'> & {
  rotate?: number | string;
}) => {
  return (
    <motion.div
      transition={{
        duration: 30,
        ease: 'linear',
        repeat: Number.POSITIVE_INFINITY,
        repeatType: 'loop',
        ...transition,
      }}
      className={cn(
        'absolute left-1/2 top-1/2 -translate-y-1/2 bg-brand/20 w-[calc(50%_-_8px)] h-px origin-left',
        className,
      )}
      {...props}
    >
      {children}
    </motion.div>
  );
};

type TipProps = React.ComponentPropsWithoutRef<typeof AnimatedSlot> & {
  children: React.ReactNode;
  asChild?: boolean;
};
const Tip: React.FC<TipProps> = ({
  asChild,
  className,
  style,
  transition,
  ...props
}) => {
  const Comp = asChild ? AnimatedSlot : motion.div;

  return (
    <Comp
      {...props}
      className={cn(
        'absolute top-1/2 left-full w-4 h-4 rounded-full origin-center',
        className,
      )}
      style={{ translateX: '-50%', translateY: '-50%', ...style }}
      // animate={{ rotate: '360deg' }}
      transition={{
        repeatType: 'loop',
        duration: 30,
        ease: 'linear',
        repeat: Number.POSITIVE_INFINITY,
        ...transition,
      }}
    />
  );
};

export const RotatingLine = Object.assign(Line, { Tip });
