import OnRampDemoImage from '@landing/assets/solutions/ramp/on-ramp-demo.png';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import { useState } from 'react';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

import { PixelAlien } from '../components/PixelAlien';
import { Color, ColorPalette } from '../components/ColorPalette';

export default function RampSlideContent() {
  const { direction } = useCarouselContext();
  const [rampColor, setRampColor] = useState<Color>(Color.BLUE);
  return (
    <motion.div
      variants={slideContentVariants}
      initial='hidden'
      animate='show'
      className='relative px-16 py-12 md:cursor-tailless md:mt-4 md:pl-0 md:py-0 md:pr-11 md:rounded-none'
    >
      <div className='relative md:left-[19.78798587%] w-fit mx-auto'>
        <MotionImage
          src={OnRampDemoImage}
          alt=''
          custom={direction}
          variants={slideContentItemVariants}
          className='max-w-[619px] max-md:max-w-[427px] drop-shadow-glow'
        />
        <PixelAlien
          className='absolute -left-[7.75006055%] -top-[6.58436214%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <ColorPalette
          custom={direction}
          variants={slideContentItemVariants}
          color={rampColor}
          onChange={setRampColor}
          className='absolute -bottom-[3.7037037%] mx-auto w-fit inset-x-0 z-20'
        />
      </div>
      <div className='[--fade-out-size:8rem] absolute z-10 h-full left-full top-0 w-[calc(var(--page-margin)_+_var(--fade-out-size))] -translate-x-[var(--fade-out-size)] bg-[linear-gradient(to_right,transparent,white_var(--fade-out-size),white)]' />
    </motion.div>
  );
}
