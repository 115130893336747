import(/* webpackMode: "eager" */ "/app/apps/frontend/public/landing-page/sphere-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["FAQ"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/FaqSection/components/FAQ.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeaturesGrid"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Features/FeaturesGrid/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Partners"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Hero/Partners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardSolutions"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Solutions/DashboardSolutions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeveloperTools"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Solutions/DeveloperTools/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RampSolutions"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Solutions/RampSolutions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentMethodsMarquee"] */ "/app/apps/frontend/src/app/[lang]/(landing)/(root)/Solutions/SolutionsHeader/PaymentMethodsMarquee.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/cta-section-isometric.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/hero-dashboard.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/hero-payments.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/hero-ramp.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/person-and-euro.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/person-and-usd.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/person-and-yen.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/solutions-business-globe.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/assets/solutions-ramp-phone.png");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/[lang]/(landing)/components/Motion.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/(root)/Features/FeaturesHeader/index.tsx\",\"import\":\"Sofia_Sans_Condensed\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\"}],\"variableName\":\"sofiaSansCondensed\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/(root)/Hero/index.tsx\",\"import\":\"Sofia_Sans_Extra_Condensed\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\"}],\"variableName\":\"sofia_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/(root)/Solutions/SolutionsHeader/index.tsx\",\"import\":\"Sofia_Sans_Condensed\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\"}],\"variableName\":\"sofiaSansCondensed\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/(root)/CTASection/index.tsx\",\"import\":\"Sofia_Sans_Extra_Condensed\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\"}],\"variableName\":\"sofia_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/(root)/FaqSection/index.tsx\",\"import\":\"Sofia_Sans_Extra_Condensed\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\"}],\"variableName\":\"sofia_sans\"}");
