export enum SphereHostedApplications {
  // Ramp Widget
  STAGING_RAMP_WIDGET = 'application_bda6ece576d34969af7752388596fa67',
  PRODUCTION_RAMP_WIDGET = 'application_e766871a7f3d438580871da23199ba4e',
  // Telegram Bot
  STAGING_TELEGRAM_BOT = 'application_8f45922dc6554db8bb2c7a545493a3d8',
  PRODUCTION_TELEGRAM_BOT = 'application_b252ba1fab34429ab1bef22798de9af3',
}

export enum SphereNestServiceName {
  API = 'api',
  CONSUMER = 'consumer',
  JOBS = 'jobs',
  SOLANA_EVENT_HANDLER = 'solana-event-handler',
  SOLANA_SUBSCRIPTION_BILLER = 'solana-subscription-biller',
  TELEGRAM_BOT = 'telegram-bot',
}

export enum ApplicationAccess {
  EARLY = 'earlyAccess',
  CREDIT_CARD = 'creditCard',
  PAYOUT = 'payout',
  CUSTODIAL_WALLET = 'custodialWallet',
  JUPITER_NODE = 'jupiterNode',
}

export enum SphereApiVersion {
  ONE = 'v1',
  TWO = 'v2',
}
export enum Role {
  Admin = 'ADMIN',
  ViewOnly = 'VIEW_ONLY',
}

export enum CustomerType {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export enum CustomerKycStatus {
  Incomplete = 'incomplete',
  AdditionalReviewRequired = 'additionalReviewRequired',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum CustomerBusinessType {
  COOPERATIVE = 'cooperative',
  CORPORATION = 'corporation',
  LLC = 'llc',
  PARTNERSHIP = 'partnership',
  SOLE_PROPRIETORSHIP = 'soleProprietorship',
  TRUST = 'trust',
}

export enum CustomerAdditionalReviewRequiredReason {
  ACTION_REQUIRED_SUMSUB_HAS_PUT_CUSTOMER_ON_HOLD = 'actionRequiredSumsubHasPutCustomerOnHold',
  ACTION_REQUIRED_CUSTOMER_IS_OVER_65_YEARS_OLD = 'actionRequiredCustomerIsOver65YearsOld',
  ACTION_REQUIRED_CUSTOMER_CREATED_RISKY_WALLET = 'actionRequiredCustomerCreatedRiskyWallet',
  NO_ACTION_REQUIRED_CUSTOMER_IS_BEING_MANUALLY_REVIEWED_BY_BRIDGE = 'noActionRequiredCustomerIsBeingManuallyReviewedByBridge',
  NO_ACTION_REQUIRED_CUSTOMER_IS_BEING_MANUALLY_REVIEWED_BY_SUMSUB = 'noActionRequiredCustomerIsBeingManuallyReviewedBySumsub',
}

export enum CustomerTosStatus {
  Incomplete = 'incomplete',
  Pending = 'pending',
  Approved = 'approved',
}

export enum CustomerMsaStatus {
  Incomplete = 'incomplete',
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  Voided = 'voided',
}

export enum CustomerOperatingResidencyStatus {
  Incomplete = 'incomplete',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum KYCStatus {
  NotStarted = 'Not Started',
  InReview = 'KYC In Review',
  Completed = 'Completed',
  Failed = 'Failed',
  Disabled = 'Disabled Until KYC',
  Linked = 'Linked',
}

export enum Permission {
  ALL = 'ALL',
  PAYMENT_LINKS = 'PAYMENT_LINKS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  INVOICES = 'INVOICES',
  PRODUCTS = 'PRODUCTS',
  BALANCES = 'BALANCES',
  CUSTOMERS = 'CUSTOMERS',
  DEVELOPER = 'DEVELOPER',
  SETTINGS = 'SETTINGS',
}

export enum PriceType {
  ONE_TIME = 'oneTime',
  RECURRING = 'recurring',
}

export enum CouponTerm {
  ONE_TIME = 'oneTime',
  FOREVER = 'forever',
  REPEATING = 'repeating',
}

export enum CouponType {
  FLAT = 'flat',
  PERCENT = 'percent',
}

export enum PayoutCurrency {
  USD = 'usd',
  EUR = 'eur',
  USDC = 'usdc',
  USDT = 'usdt',
}

export enum TaxBehavior {
  INCLUSIVE = 'inclusive',
  EXCLUSIVE = 'exclusive',
  UNSPECIFIED = 'unspecified',
}

export enum TierType {
  GRADUATED = 'graduated',
  VOLUME = 'volume',
}

export enum RecurringType {
  ESCROWED = 'escrowed',
  DELEGATED = 'delegated',
}

export enum PayoutStatus {
  PENDING = 'pending',
  FUNDED = 'funded',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
}

export enum PayoutOnOffRampProvider {
  Bridge = 'bridge',
  BVNK = 'bvnk',
}

export enum TaxRateType {
  VALUE_ADDED_TAX = 'valueAddedTax',
  SALES_TAX = 'salesTax',
}

export enum ShippingRateType {
  FIXED_AMOUNT = 'fixedAmount',
}

export enum InfrastructureEnv {
  TEST = 'test',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum UpdateSubscriptionAction {
  Cancel = 'cancel',
  TopUp = 'topUp',
  Reactivate = 'reactivate',
}

export enum ProgramKey {
  LOCALNET = 'localnet',
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
  NELSON_MAINNET = 'nelson-mainnet',
}

export enum viewStatus {
  selection = 'selection',
  form = 'form',
  payoutSuccessful = 'payoutSuccessful',
}

export enum PayoutComputerType {
  BankAccount = 'bankAccount',
  Wallet = 'wallet',
}

export enum BankAccountProvider {
  // currently all bank accounts added in Sphere are bridge bank accounts which doesnt make any sense imo.
  //Those should be "sphere" bank accounts, "bridge" bank accounts should be those that are added via bridge's plaid flow
  BRIDGE = 'bridge',
  TELLER = 'teller', // bank accounts that are added via teller
  PLAID = 'plaid', // bank accounts that are added via plaid
}

export enum RoutingNumberType {
  ACH = 'ach',
  WIRE = 'wire',
  UNKNOWN = 'unknown', // before this commit we did not collect a customer's routing number type
}

export enum BankAccountOwner {
  CUSTOMER = 'customer', // A bankAccount will have an owner of customer iff the bankAccount belongs to a Customer.
  BRIDGE = 'bridge', // A bankAccount will have an owner of bridge iff the bankAccount belongs to Bridge.
}

export enum WalletOwner {
  CUSTOMER = 'customer', // A wallet will have an owner of customer iff the wallet belongs to a Customer.
  MERCHANT = 'merchant', // A wallet will have an owner of merchant iff the wallet belongs to the merchant, ie at the level of application.
  BRIDGE = 'bridge', // A wallet will have an owner of bridge iff the wallet belongs to Bridge.
}

export enum Network {
  LOCALHOST = 'localhost',
  ETH_MAINNET = 'ethereum',
  POLYGON_MAINNET = 'polygon',
  GNOSIS_MAINNET = 'gnosis',
  OPTIMISM_MAINNET = 'optimism',
  ARBITRUM_MAINNET = 'arbitrum',
  BSC_MAINNET = 'bsc',
  BASE_MAINNET = 'base',
  SEPOLIA_TESTNET = 'sepolia',
  SOL = 'sol',
  WIRE = 'wire',
  ACH = 'ach',
  ACH_PUSH = 'achPush',
  ACH_PULL = 'achPull',
  ACH_SAME_DAY = 'achSameDay',
  SEPA = 'sepa',
  STRIPE = 'stripe',
  BITCOIN = 'bitcoin',
}

export enum SolanaExplorer {
  EXPLORER = 'explorer',
  SOLSCAN = 'solscan',
  SOLANAFM = 'solanafm',
  XRAY = 'xray',
}

export enum PayoutNetwork {
  WIRE = 'wire',

  /**
   * Only used for payout source
   */
  ACH_PULL = 'achPull',

  /**
   * Only used for payout source
   */
  ACH_PUSH = 'achPush',

  /**
   * Only used for payout destination
   * If supplied, for payout source, it will be converted
   * to achPush for backwards compatibility
   */
  ACH = 'ach',

  /**
   * Only used for payout destination
   * If supplied, for payout source, it will be converted
   * to achPush for backwards compatibility
   */
  ACH_SAME_DAY = 'achSameDay',

  /**
   * Only used for EUR payouts
   */
  SEPA = 'sepa',

  ETH = 'ethereum',
  SOL = 'sol',
  OPTIMISM = 'optimism',
  ARBITRUM = 'arbitrum',
  POLYGON = 'polygon',
  BASE = 'base',
}

export enum StatusPayout {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum BillingScheme {
  PER_UNIT = 'perUnit',
  TIERED = 'tiered',
}

export enum RecurringUsageAggregation {
  SUM_USAGE = 'sum',
  LATEST = 'latest',
  MAX_USAGE = 'maxUsage',
}

export enum RecurringInterval {
  MIN = 'min',
  MONTH = 'month',
  YEAR = 'year',
  WEEK = 'week',
  DAY = 'day',
}

export enum RecurringUsageType {
  LICENSED = 'licensed',
  METERED = 'metered',
}

export enum PayoutType {
  OffRamp = 'offRamp',
  OnChain = 'onChain',
  OnRamp = 'onRamp',
}

export enum BankAccountBankType {
  US = 'us',
  IBAN = 'iban',
}

export enum BankAccountType {
  Checking = 'checking',
  Savings = 'savings',
}

// This is how the states change:
// onCreate: "pending" -> "active" | "inactive"
// onDelete: "active" | "inactive" | "pending" -> "inactive"
export enum BankAccountStatus {
  Pending = 'pending', // on create, before we have created the bankAccount in bridge, before it has a bridgeExternalAccountId and can be used in payouts.
  Active = 'active', // the bankAccount contains a valid bridgeExternalAccountId and is ready for use in bridge payouts.
  Inactive = 'inactive', // this one should be deprecated imo, rn it is set to inactive on softDelete of a bankAccount.
  Invalid = 'invalid', // if we try to create the bankAccount in bridge but it fails (could be for a variety of reasons), we set the status to invalid.
}

export enum MimeType {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  OCTET_STREAM = 'application/octet-stream',
}

export enum TransactionType {
  PAYMENT = 'payment',
  REFUND = 'refund',
}

export enum FileType {
  PRODUCT_IMAGE = 'productImage', // for product images
  CUSTOMER_IDENTITY_DOCUMENT = 'customerIdentityDocument', // for customer of type=individual
  CUSTOMER_PROOF_OF_RESIDENCE = 'customerProofOfResidence', // for customer of type=individual
  CUSTOMER_SHAREHOLDER_DOCUMENT = 'customerShareholderDocument', // for customers of type=business
  CUSTOMER_INCORPORATION_DOCUMENT = 'customerIncorporationDocument', // for customers of type=business
  CUSTOMER_STATEMENT_OF_FUNDS = 'customerStatementOfFunds', // for customer of type=business
}

export enum FileStorageLocation {
  FIREBASE_STORAGE = 'firebaseStorage',
  GCP_STORAGE = 'gcpStorage',
  SUMSUB = 'sumsub',
}

export enum FileStorageBucket {
  PRODUCT_IMAGES = 'product-images',
  CUSTOMER_DOCUMENTS = 'customer-documents',
}

export enum PaymentLinkStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum PaymentType {
  SUBSCRIPTION = 'subscription',
  PAYMENT_LINK = 'paymentLink',
  INVOICE = 'invoice',
  STRIPE = 'stripe',
}

export enum PaymentError {
  SUBSCRIPTION_CANCELLED = 'subscriptionCancelled',
  SUBSCRIPTION_NOT_DUE = 'subscriptionNotDue',
  SUBSCRIPTION_BILLER_COULD_NOT_SEND_TRANSACTION_TO_CHAIN = 'subscriptionBillerCouldNotSendTransactionToChain',
  CUSTOMER_DID_NOT_SEND_TRANSACTION_TO_CHAIN = 'customerDidNotSendTransactionToChain',
  SUBSCRIPTION_TOKEN_ACCOUNT_INSUFFICIENT_DELEGATED_BALANCE = 'subscriptionTokenAccountInsufficientDelegatedBalance',
  SUBSCRIPTION_TOKEN_ACCOUNT_INSUFFICIENT_DELEGATED_APPROVED_BALANCE = 'subscriptionTokenAccountInsufficientDelegatedApprovedBalance',
  SUBSCRIPTION_TOKEN_ACCOUNT_INSUFFICIENT_ESCROWED_BALANCE = 'subscriptionTokenAccountInsufficientEscrowedBalance',
  SUBSCRIPTION_TOKEN_ACCOUNT_REDELEGATED = 'subscriptionTokenAccountRedelegated',
}

export enum PaymentRails {
  FIAT = 'fiat',
  CRYPTO = 'crypto',
}

export enum PaymentFlow {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum PaymentStatus {
  PENDING = 'pending',
  CONFIRMING = 'confirming',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  REFUNDED = 'refunded',
}

export enum SubscriptionStatus {
  // A subscription is set to 'pending' on creation of the subscription (ie: when someone clicks the pay button). Whenever you call SubscriptionEntity.new({...}) the resulting subscription is in status pending.
  PENDING = 'pending',
  // A subscription becomes incomplete if the initial payment attempt fails.
  INCOMPLETE = 'incomplete',
  // A subscription becomes active once we detect onchain that the customer has escrowed/delegated the required amount of tokens.
  ACTIVE = 'active',
  // A subscription moves to pastDue when the payment is required but cannot be paid due to failed payment or awaiting additional user interactions(ie: updating their subscription to approve or escrow more tokens).
  PAST_DUE = 'pastDue',
  // A subscription moves to cancelled if it is pastDue and we have attempted to bill the Subscription three times without success.
  CANCELED = 'canceled',
}

export enum SubscriptionCancellationReason {
  COMPLETE = 'complete',
  INSUFFICIENT_DELEGATED_BALANCE = 'insufficientDelegatedBalance',
  INSUFFICIENT_DELEGATED_APPROVED_BALANCE = 'insufficientDelegatedApprovedBalance',
  INSUFFICIENT_ESCROWED_BALANCE = 'insufficientEscrowedBalance',
  REDELEGATED = 'redelegated',
  CUSTOMER_HAS_CANCELLED = 'customerHasCancelled',
}

export enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  VOID = 'void',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum InvoiceBillingReason {
  SUBSCRIPTION_CYCLE = 'subscriptionCycle',
  SUBSCRIPTION_CREATE = 'subscriptionCreate',
  SUBSCRIPTION_UPDATE = 'subscriptionUpdate',
  MANUAL = 'manual',
  UPCOMING = 'upcoming',
  SUBSCRIPTION_THRESHOLD = 'subscriptionThreshold',
}

export enum RefundStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum WebhookStatus {
  HEALTHY = 'healthy',
  DEGRADED = 'degraded',
  FAILING = 'failing',
  DEAD = 'dead',
}

export enum BridgeState {
  PENDING = 'pending',
  AWAITING_SOURCE_DEPOSIT = 'awaitingSourceDeposit',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum AlertSeverity {
  DEBUG = 'debug',
  INFO = 'info',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export enum AlertType {
  NOTIFICATION = 'notification',
  ACTION_REQUIRED = 'actionRequired',
  SECURITY = 'security',
  REMINDER = 'reminder',
  MILESTONE = 'milestone',
}

export enum RequestType {
  INGRESS = 'ingress',
  EGRESS = 'egress',
}

export enum SphereEventNames {
  // All
  ALL = '*',
  // Address
  CREATE_ADDRESS = 'address.create',
  // Alert
  CREATE_ALERT = 'alert.create',
  UPDATE_ALERT = 'alert.update',
  // Tax Rate
  CREATE_TAX_RATE = 'taxRate.create',
  DELETE_TAX_RATE = 'taxRate.delete',
  UPDATE_TAX_RATE = 'taxRate.update',
  // Shipping Rate
  CREATE_SHIPPING_RATE = 'shippingRate.create',
  // ApiKey
  CREATE_API_KEY = 'apiKey.create',
  UPDATE_API_KEY = 'apiKey.update',
  DELETE_API_KEY = 'apiKey.delete',
  // RestrictedApiKey
  CREATE_RESTRICTED_API_KEY = 'restrictedApiKey.create',
  UPDATE_RESTRICTED_API_KEY = 'restrictedApiKey.update',
  DELETE_RESTRICTED_API_KEY = 'restrictedApiKey.delete',
  // Application
  UPDATE_APPLICATION = 'application.update',
  // Customer
  CREATE_CUSTOMER = 'customer.create',
  UPDATE_CUSTOMER = 'customer.update',
  DELETE_CUSTOMER = 'customer.delete',
  APPROVE_CUSTOMER = 'customer.approved',
  TOS_CUSTOMER_PENDING = 'customer.tos.pending',
  TOS_CUSTOMER_SUCCESS = 'customer.tos.successful',
  KYC_CUSTOMER_PENDING = 'customer.kyc.pending',
  KYC_CUSTOMER_SUCCESS = 'customer.kyc.successful',
  KYC_ADDITIONAL_REVIEW_REQUIRED = 'customer.kyc.additionalReviewRequired',
  KYC_CUSTOMER_REJECTED = 'customer.kyc.rejected',
  // Coupons
  CREATE_COUPON = 'coupon.create',
  UPDATE_COUPON = 'coupon.update',
  DELETE_COUPON = 'coupon.delete',
  // Promotion Codes
  CREATE_PROMOTION_CODE = 'promotionCode.create',
  UPDATE_PROMOTION_CODE = 'promotionCode.update',
  DELETE_PROMOTION_CODE = 'promotionCode.delete',
  // NFT Discount
  CREATE_NFT_DISCOUNT = 'nftDiscount.create',
  UPDATE_NFT_DISCOUNT = 'nftDiscount.update',
  DELETE_NFT_DISCOUNT = 'nftDiscount.delete',
  // EMAIL
  CREATE_EMAIL = 'email.create',
  // FILE
  CREATE_FILE = 'file.create',
  // Payment
  PENDING_PAYMENT = 'payment.pending',
  CANCELLED_PAYMENT = 'payment.cancelled',
  SUCCESSFUL_PAYMENT = 'payment.successful',
  INCOMPLETE_PAYMENT = 'payment.incomplete',
  FAILED_PAYMENT = 'payment.failed',
  REFUNDED_CARD_PAYMENT = 'payment.card.refunded',
  // PaymentLink
  CREATE_PAYMENT_LINK = 'paymentLink.create',
  DELETE_PAYMENT_LINK = 'paymentLink.delete',
  UPDATE_PAYMENT_LINK = 'paymentLink.update',
  // PaymentLinkPhase
  CREATE_PAYMENT_LINK_PHASE = 'phase.create',
  UPDATE_PAYMENT_LINK_PHASE = 'phase.update',
  // PaymentLinkPhaseLimit
  CREATE_PAYMENT_LINK_PHASE_LIMIT = 'limit.create',
  UPDATE_PAYMENT_LINK_PHASE_LIMIT = 'limit.update',
  // Price
  CREATE_PRICE = 'price.create',
  DELETE_PRICE = 'price.delete',
  UPDATE_PRICE = 'price.update',
  // Product
  CREATE_PRODUCT = 'product.create',
  DELETE_PRODUCT = 'product.delete',
  UPDATE_PRODUCT = 'product.update',
  // Refund
  PENDING_REFUND = 'refund.pending',
  SUCCESSFUL_REFUND = 'refund.successful',
  INCOMPLETE_REFUND = 'refund.incomplete',
  FAILED_REFUND = 'refund.failed',
  UPDATE_REFUND = 'refund.update',
  // Transaction
  CREATE_TRANSACTION = 'transaction.create',
  UPDATE_TRANSACTION = 'transaction.update',
  // Subscription
  CREATE_SUBSCRIPTION = 'subscription.create', // Emitted on creation of a subscription. In particular this event is emitted when a customer clicks the pay button on a paymentLink where the lineItems prices have a type of 'recurring'.
  UPDATE_SUBSCRIPTION = 'subscription.update', // Emitted when the customer or merchant has updated the subscription (currently only the customer can call this by updating the subscription)
  PAST_DUE_SUBSCRIPTION = 'subscription.pastDue', // Emitted when the subscription changes state to `pastDue`.
  CANCEL_SUBSCRIPTION = 'subscription.cancel', // Emitted when the subscription changes state to `cancelled`. fuck me this should have been `subscription.cancelled` instead of `subscription.cancel`.
  BILL_SUBSCRIPTION = 'subscription.bill', // Emitted after we have attempted to bill the subscription (Emitted regardless of if the transaction fails or succeeds)
  RETRY_BILL_SUBSCRIPTION = 'subscription.bill.retry', // Emitted when a subscription is in retry and we attempt to bill it again.
  APPROVE_SUBSCRIPTION = 'subscription.approve', // Emitted after we have detected that the customer has approved or escrowed funds to fund the subscription.
  LOW_BALANCE_ESCROWED_SUBSCRIPTION = 'subscription.escrowed.lowBalance', // Emitted if the escrowed balance in the subscription is running low.
  LOW_BALANCE_DELEGATED_SUBSCRIPTION = 'subscription.delegated.lowBalance', // Emitted if the delegated balance in the subscription is running low.
  INSUFFICIENT_DELEGATED_BALANCE_SUBSCRIPTION = 'subscription.delegated.insufficientBalance', // Emitted if the delegated token account contains insufficient tokens for billing.
  INSUFFICIENT_DELEGATED_APPROVED_BALANCE_SUBSCRIPTION = 'subscription.delegated.insufficientApprovedBalance', // Emitted if the the subscription does not have enough tokens delegated for billing.
  INSUFFICIENT_ESCROWED_BALANCE_SUBSCRIPTION = 'subscription.escrowed.insufficientBalance', // Emitted if the escrowed token account contains insufficient tokens for billing.
  REDELEGATED_SUBSCRIPTION = 'subscription.delegated.redelegated', // Emitted if the delegated token account has been redelegated.
  // Usage Record
  CREATE_USAGE_RECORD = 'usageRecord.create',
  // // Bank Account
  CREATE_BANK_ACCOUNT = 'bankAccount.create',
  UPDATE_BANK_ACCOUNT = 'bankAccount.update',
  // Payout
  CREATE_PAYOUT = 'payout.create', // Occurs immediately when you create a payout.
  PENDING_PAYOUT = 'payout.pending', // Occurs when we query bridge and can confirm that the BridgeTransfer object is in "awaiting_funds" state.
  FUNDED_PAYOUT = 'payout.funded', // Triggered when they hit the /public/payout endpoint, execute the solana tx, and we receive the event that the funds have move to the bridge deposit address.
  DISPATCHED_PAYOUT = 'payout.dispatched', // Triggered when BridgeTransfer object moves to "funds_received".
  PROCESSING_PAYOUT = 'payout.processing', // Triggered when BridgeTransfer object moves to "payment_submitted".
  SUCCESSFUL_PAYOUT = 'payout.successful', // Triggered when BridgeTransfer object moves to "payment_processed".
  CANCELLED_PAYOUT = 'payout.cancelled', // Triggered when BridgeTransfer object is deleted in via bridge API and payout is set to cancelled.
  FAILED_PAYOUT = 'payout.failed', // Triggered when BridgeTransfer object moves to "cancelled".
  REFUNDED_PAYOUT = 'payout.refunded', // When bridge tells us that the payout has been refunded.
  // Webhook
  CREATE_WEBHOOK = 'webhook.create',
  DELETE_WEBHOOK = 'webhook.delete',
  UPDATE_WEBHOOK = 'webhook.update',
  // Wallet
  CREATE_WALLET = 'wallet.create',
  // Custodial
  CUSTODIAL_ACTIVATION_SUCCESSFUL = 'custodial.activationSuccessful',
}

export enum SolanaEventNames {
  EMPTY = 'Empty',
  FAILED = 'Failed',
  INVALID = 'Invalid',
  // Config
  CREATE_CONFIG = 'createConfigEvent',
  UPDATE_CONFIG = 'updateConfigEvent',
  // Payout
  CREATE_WALLET_TO_BANK_ACCOUNT_PAYOUT = 'createOffRampPayoutEvent',
  FUND_WALLET_TO_BANK_ACCOUNT_PAYOUT = 'fundOffRampPayoutEvent',
  DISBURSE_WALLET_TO_BANK_ACCOUNT_PAYOUT = 'disburseOffRampPayoutEvent',
  // Subscription
  BILL_SUBSCRIPTION_SUCCESS = 'billSubscriptionSuccessEvent',
  BILL_SUBSCRIPTION_NOT_DUE = 'billSubscriptionNotDueEvent',
  BILL_SUBSCRIPTION_INSUFFICIENT_DELEGATED_BALANCE = 'billSubscriptionInsufficientDelegatedBalanceEvent',
  BILL_SUBSCRIPTION_INSUFFICIENT_DELEGATED_APPROVED_BALANCE = 'billSubscriptionInsufficientDelegatedApprovedBalanceEvent',
  BILL_SUBSCRIPTION_INSUFFICIENT_ESCROWED_BALANCE = 'billSubscriptionInsufficientEscrowedBalanceEvent',
  BILL_SUBSCRIPTION_DELEGATE_REDELEGATED = 'billSubscriptionDelegateRedelegatedEvent',
  BILL_SUBSCRIPTION_INACTIVE = 'billSubscriptionInactiveEvent',
  CREATE_SUBSCRIPTION = 'createSubscriptionEvent',
  APPROVE_SUBSCRIPTION = 'approveSubscriptionEvent',
  UPDATE_SUBSCRIPTION = 'updateSubscriptionEvent',
  // Transfer
  TRANSFER = 'transfer',
}

export enum DepinNetwork {
  HELIUM_IOT = 'heliumIot',
  HELIUM_MOBILE = 'heliumMobile',
}

export enum SolanaDepinSessionStatus {
  PENDING = 'pending',
  FULFILLING = 'fulfilling',
  SUCCESSFUL = 'success',
  FAILED = 'failed',
}

export enum ROLES {
  Admin = 'ADMIN',
  ViewOnly = 'VIEW_ONLY',
}

export enum PERMISSIONS {
  ALL = 'ALL',
  PAYMENT_LINKS = 'PAYMENT_LINKS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  INVOICES = 'INVOICES',
  PRODUCTS = 'PRODUCTS',
  BALANCES = 'BALANCES',
  CUSTOMERS = 'CUSTOMERS',
  DEVELOPER = 'DEVELOPER',
  SETTINGS = 'SETTINGS',
}

export enum BankAccountCurrency {
  USD = 'usd',
  EUR = 'eur',
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum PaymentLinkPhaseConstraintType {
  PHASE = 0,
  PHASE_LIMIT = 1,
}

export enum BtcPaymentStatus {
  PAID = 'paid',
  UNDER = 'underpaid',
  OVER = 'overpaid',
  PAID_MEMPOOL = 'paidMempool',
  UNDER_MEMPOOL = 'underpaidMempool',
  OVER_MEMPOOL = 'overpaidMempool',
  UNPAID = 'unpaid',
}

export enum PaymentMethod {
  CRYPTO = 'crypto',
  CARD = 'card',
}

export enum CustomFieldType {
  TEXT = 'text',
  LONG_TEXT = 'longText',
  NUMBER = 'number',
}

export enum CustodyProvider {
  DFNS = 'dfns',
}

export enum RestrictedApiKeyPermissions {
  PAYMENT_LINK_READ = 'paymentLink.read',
  TAX_RATE_READ = 'taxRate.read',
  SHIPPING_RATE_READ = 'shippingRate.read',
}

export enum FeeSetter {
  SPHERE = 'sphere',
  MERCHANT = 'merchant',
}
