import EventsImage from '@landing/assets/solutions/dashboard/events.png';
import WebhooksChartImage from '@landing/assets/solutions/dashboard/webhooks-chart.png';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';
import Image from 'next/image';

export default function DeveloperToolkitSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      className='relative md:absolute right-0 w-full max-md:pb-6 md:mt-12'
      initial='hidden'
      animate='show'
      variants={slideContentVariants}
      custom={direction}
    >
      <div className='aspect-[605/503] relative md:absolute right-0 min-w-[560px] max-w-[605px] w-full ml-auto '>
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={EventsImage}
          alt=''
          className='absolute mr-auto w-[88.92561983%] max-w-[538px] drop-shadow-glow'
        />
        <motion.div
          variants={slideContentItemVariants}
          custom={direction}
          className='absolute top-1/2  mt-[2.5%] right-0 max-w-[431px] w-[71.23966942%] drop-shadow-glow'
        >
          <Image
            src={WebhooksChartImage}
            alt=''
            className='!-translate-y-1/2'
          />
        </motion.div>
        ;
      </div>
    </motion.div>
  );
}
