import ach from './ach.svg?url';
import americanExpress from './american-express.svg?url';
import arbitrum from './arbitrum.svg?url';
import base from './base.svg?url';
import bitcoin from './bitcoin.svg?url';
import bsc from './bsc.svg?url';
import discover from './discover.svg?url';
import ethereum from './ethereum.svg?url';
import gnosis from './gnosis.svg?url';
import mastercard from './mastercard.svg?url';
import opMainnet from './op-mainnet.svg?url';
import polygon from './polygon.svg?url';
import sepa from './sepa.svg?url';
import solana from './solana.svg?url';
import tron from './tron.svg?url';
import visa from './visa.svg?url';
import wire from './wire.svg?url';

export {
  ach,
  americanExpress,
  arbitrum,
  base,
  bitcoin,
  bsc,
  discover,
  ethereum,
  gnosis,
  mastercard,
  opMainnet,
  polygon,
  sepa,
  solana,
  tron,
  visa,
  wire,
};
