import BsaAmlImage from '@landing/assets/features/securecy/bsa-aml.png';
import DepartmentOfTheTreasuryImage from '@landing/assets/features/securecy/department-of-the-treasury.png';
import FederalTradeComissionImage from '@landing/assets/features/securecy/federal-trade-comission.png';
import GdprImage from '@landing/assets/features/securecy/gdpr.png';
import PciDssImage from '@landing/assets/features/securecy/pci-dss-1.png';

import Image from 'next/image';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Marquee } from '../../../components/Marquee';
import { useTranslation } from 'react-i18next';

export function SecurityMarquee() {
  const [marqueeChildWidth, setMarqueeChildWidth] = useState<number>(0);
  const { t } = useTranslation();

  const marqueeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function listener() {
      const parent = marqueeRef.current;
      if (!parent) return;
      const parentOffsetWidth = parent.offsetWidth;
      const marqueeChildWidth = parentOffsetWidth / 2.5;

      setMarqueeChildWidth(marqueeChildWidth);
    }
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <Marquee
      className='flex items-center pb-4 w-full h-full'
      ref={marqueeRef}
      style={
        {
          '--marquee-child-width': `${marqueeChildWidth}px`,
        } as React.CSSProperties
      }
    >
      <Marquee.Container className='w-full'>
        <Marquee.Content speed={10}>
          <SecurecyMarqueeItem>
            <Image
              src={BsaAmlImage}
              alt='BSA/AML'
              className='w-auto object-contain'
            />
            <p className='text-wrap text-center uppercase'>
              AML fraud detection
            </p>
          </SecurecyMarqueeItem>
          <SecurecyMarqueeItem>
            <Image
              src={DepartmentOfTheTreasuryImage}
              alt='Department of the treasury'
              className='w-auto object-contain'
            />
            <p className='text-wrap text-center uppercase whitespace-pre-line'>
              {t('features.securityMarquees.0')}
            </p>
          </SecurecyMarqueeItem>
          <SecurecyMarqueeItem>
            <Image
              src={FederalTradeComissionImage}
              alt=''
              className='w-auto object-contain'
            />
            <p className='text-wrap text-center uppercase whitespace-pre-line'>
              {t('features.securityMarquees.1')}
            </p>
          </SecurecyMarqueeItem>
          <SecurecyMarqueeItem>
            <Image src={GdprImage} alt='' className='w-auto object-contain' />
            <p className='text-wrap text-center uppercase whitespace-pre-line'>
              {t('features.securityMarquees.2')}
            </p>
          </SecurecyMarqueeItem>
          <SecurecyMarqueeItem>
            <Image
              src={PciDssImage}
              alt=''
              className='max-w-[96px] w-auto object-contain'
            />
            <p className='text-wrap text-center uppercase whitespace-pre-line'>
              {t('features.securityMarquees.3')}
            </p>
          </SecurecyMarqueeItem>
        </Marquee.Content>
      </Marquee.Container>
    </Marquee>
  );
}

function SecurecyMarqueeItem({ children }: PropsWithChildren) {
  return (
    <Marquee.Item className='h-full text-wrap text-center uppercase whitespace-pre-line bg-surface-1 aspect-[75/100] w-[var(--marquee-child-width)] min-w-[141px] inline-grid grid-rows-[minmax(1fr_50%)_auto] gap-4 place-items-center mr-4 px-4 py-8 text-subtle text-xs leading-tight rounded-[17.83%_/_13.34%] border border-outline [&>img]:object-contain'>
      {children}
    </Marquee.Item>
  );
}
