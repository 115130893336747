'use client';

import { AppBadge } from '@/common/components/AppBadge';
import { cn } from '@/common/utils/cn';

import { InfoBulletList } from '@/app/[lang]/(landing)/components/InfoBulletList';

import CodeSnippetImage from '@landing/assets/solutions/developer-tools/code-snippet.svg?url';
import { Button } from '@/app/[lang]/(landing)/components/Button/Button';
import { FileIcon, MessageCircleWarningIcon } from 'lucide-react';
import { Sofia_Sans_Condensed } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const sofiaSansCondensed = Sofia_Sans_Condensed({
  subsets: ['latin'],
  weight: 'variable',
});

export function DeveloperTools() {
  const { t } = useTranslation();

  return (
    <div className='relative bg-background-dark z-30 overflow-hidden'>
      <div className='relative flex h-fit w-full flex-col items-center justify-center mx-auto pt-[4.5rem] max-w-[min(var(--page-width),calc(100%_-_1.5rem))] md:max-w-lp'>
        <div
          className={cn(
            'w-full grid grid-cols-1 lg:grid-cols-2 auto-rows-auto gap-7',
          )}
        >
          <div className='min-w-0 flex flex-col gap-y-5 px-4 md:pr-0 md:pl-8'>
            <AppBadge
              color='primary'
              className='self-start py-1 text-sm leading-[1.125rem] bg-[#0A2240] text-brand-variant border border-brand-variant'
            >
              {t('developerTools.topBadge')}
            </AppBadge>
            <h3
              className={cn(
                sofiaSansCondensed.className,
                'font-[800]',
                'text-[3.625rem] uppercase leading-[3.5rem] text-inverse',
              )}
            >
              {t('developerTools.header')}
            </h3>
            <p className='text-inverse/70 font-light leading-7 md:pr-16'>
              {t('developerTools.subHeading')}
            </p>
            <InfoBulletList className='gap-y-5 text-base leading-7 text-inverse font-light [&_svg]:*:size-5 [&_svg]:*:text-brand-variant *:[&_svg]:stroke-background-dark'>
              <InfoBulletList.Item>
                {t('developerTools.bullets.0')}
              </InfoBulletList.Item>
              <InfoBulletList.Item>
                {t('developerTools.bullets.1')}
              </InfoBulletList.Item>
              <InfoBulletList.Item>
                {t('developerTools.bullets.2')}
              </InfoBulletList.Item>
            </InfoBulletList>

            <div
              className={cn(
                'w-full flex flex-col items-center gap-4 mt-6 md:pb-24 md:w-fit md:flex-row md:px-0',
                '*:max-w-lg *:w-full *:md:w-fit md:*:max-w-none',
              )}
            >
              <Button
                asChild
                variant='default'
                className='flex gap-x-1 items-center h-11 md:h-9'
              >
                <Link href='https://docs.spherepay.co/'>
                  {t('developerTools.ctas.primary')}
                  <FileIcon className='w-[1.125rem] h-auto' strokeWidth={1.5} />
                </Link>
              </Button>
              <Button
                asChild
                variant='secondary'
                className='flex gap-x-2 items-center h-11 md:h-9 bg-button-secondary-dark hover:bg-button-secondary-dark-hover text-inverse border-button-secondary-dark-border'
              >
                <Link href='https://docs.spherepay.co/'>
                  {t('developerTools.ctas.secondary')}
                  <MessageCircleWarningIcon
                    className='w-[1.125rem] h-auto text-button-secondary-dark-icon'
                    strokeWidth={1.5}
                  />
                </Link>
              </Button>
            </div>
          </div>
          <div className='min-w-0 flex flex-col w-fit h-full px-4 mx-auto'>
            <Image
              src={CodeSnippetImage}
              alt='Sphere software development kit'
              className='min-w-[632px] shrink-0 relative -bottom-[5.99250936%] [background:_linear-gradient(175.04deg,_rgba(32,_34,_86,_0)_-5.88%,_#252994_67.85%)] object-contain rounded-2xl md:mt-auto'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
