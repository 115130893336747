import { SVGProps } from 'react';

export default function CheckCircleIcon({
  stroke = '#FFFFFF',
  fill = '#7387A4',
  ...props
}: SVGProps<SVGSVGElement> & { isActive?: boolean }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='10' cy='10' r='10' fill={fill} />
      <path
        d='M5 10.8333L8.54167 14.1666L15 6.66663'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
