'use client';
import { AppBadge } from '@/common/components/AppBadge';
import { cn } from '@/common/utils/cn';

import {
  CarouselSlide,
  CarouselSlider,
} from '@/app/[lang]/(landing)/components/Carousel';
import { InfoBulletList } from '@/app/[lang]/(landing)/components/InfoBulletList';

import { Sofia_Sans_Condensed } from 'next/font/google';
import { useCallback, useMemo, useState } from 'react';
import { SolutionsAccordion } from '../components/SolutionsAccordion';

import { useIsMobile } from '@/app/[lang]/(landing)/providers/MobileProvider';
import { useTranslation } from 'react-i18next';
import {
  ComplianceSlideContent,
  CurrencyConversionSlideContent,
  RampSlideContent,
} from './slides';

const sofiaSansCondensed = Sofia_Sans_Condensed({
  subsets: ['latin'],
  weight: 'variable',
});

const RampSolution = {
  RAMP: 'ramp',
  CURRENCY_CONVERSION: 'currencyConversion',
  COMPLIANCE: 'compliance',
  // TELEGRAM_BOT: 'telegramBot',
} as const;
type RampSolution = (typeof RampSolution)[keyof typeof RampSolution];

const RAMP_SOLUTIONS_LIST = [
  RampSolution.RAMP,
  RampSolution.CURRENCY_CONVERSION,
  RampSolution.COMPLIANCE,
  // RampSolution.TELEGRAM_BOT,
] as const;

export function RampSolutions() {
  const isMobile = useIsMobile();
  const [solution, setSolution] = useState<RampSolution>('ramp');
  const { t } = useTranslation();

  const handleSolutionChange = useCallback((solution: string) => {
    if (!RAMP_SOLUTIONS_LIST.includes(solution as RampSolution)) return;

    setSolution(solution as RampSolution);
  }, []);

  return (
    <div className='relative flex h-fit w-full flex-col items-center justify-center max-w-[min(var(--page-width),calc(100%_-_1.5rem))] mx-auto pt-16 pb-24 overflow-hidden'>
      <div
        className={cn('w-full grid md:grid-cols-2 auto-rows-fr gap-7 mt-16')}
      >
        <div className='min-w-0 w-full md:w-auto md:pl-11'>
          <AppBadge color='primary' className='py-1 text-sm leading-[1.125rem]'>
            {t('ramp.topBadge')}
          </AppBadge>
          <h3
            className={cn(
              sofiaSansCondensed.className,
              'font-[800]',
              'text-[3.625rem] uppercase leading-[3.5rem] mt-3.5',
            )}
          >
            {t('ramp.header')}
          </h3>
          <SolutionsAccordion
            value={solution}
            onValueChange={handleSolutionChange}
            className='min-h-[28rem]'
          >
            <SolutionsAccordion.Item
              value={RampSolution.RAMP}
              title={t('ramp.solutions.0.title')}
              badgeNumber={1}
              className='transition-colors hover:data-[state=closed]:bg-background-hover py-4'
            >
              <p>{t('ramp.subHeading')}</p>
              <InfoBulletList className='font-medium [&_svg]:*:size-5 *:text-base'>
                <InfoBulletList.Item>
                  {t('ramp.solutions.0.bullets.0')}
                </InfoBulletList.Item>
                <InfoBulletList.Item>
                  {t('ramp.solutions.0.bullets.1')}
                </InfoBulletList.Item>
                <InfoBulletList.Item>
                  {t('ramp.solutions.0.bullets.2')}
                </InfoBulletList.Item>
              </InfoBulletList>
              <AccordionContentRenderer
                isMobile={isMobile}
                solution={RampSolution.RAMP}
              />
            </SolutionsAccordion.Item>
            <SolutionsAccordion.Item
              value={RampSolution.CURRENCY_CONVERSION}
              title={t('ramp.solutions.1.title')}
              badgeNumber={2}
              className='transition-colors hover:data-[state=closed]:bg-background-hover py-4'
            >
              <p>{t('ramp.solutions.1.description')}</p>
              <AccordionContentRenderer
                isMobile={isMobile}
                solution={RampSolution.CURRENCY_CONVERSION}
              />
            </SolutionsAccordion.Item>
            <SolutionsAccordion.Item
              value={RampSolution.COMPLIANCE}
              title={t('ramp.solutions.2.title')}
              badgeNumber={3}
              className='transition-colors hover:data-[state=closed]:bg-background-hover py-4'
            >
              <p>{t('ramp.solutions.2.description')}</p>
              <AccordionContentRenderer
                isMobile={isMobile}
                solution={RampSolution.COMPLIANCE}
              />
            </SolutionsAccordion.Item>
          </SolutionsAccordion>
        </div>
        {!isMobile && <DesktopCarousel solution={solution} />}
      </div>
    </div>
  );
}

function AccordionContentRenderer({
  solution,
  isMobile,
}: {
  solution: RampSolution;
  isMobile: boolean;
}) {
  if (!isMobile) return null;

  const content = useMemo(() => {
    switch (solution) {
      case RampSolution.RAMP:
        return <RampSlideContent />;
      case RampSolution.CURRENCY_CONVERSION:
        return <CurrencyConversionSlideContent />;
      case RampSolution.COMPLIANCE:
        return <ComplianceSlideContent />;
      default:
        return null;
    }
  }, [solution]);

  return (
    <div className='w-full mt-8 bg-background-dim rounded-lg max-md:border max-md:border-outline/50 max-md:overflow-hidden md:bg-transparent'>
      {content}
    </div>
  );
}

function DesktopCarousel({ solution }: { solution: RampSolution }) {
  const currentSlideIndex = RAMP_SOLUTIONS_LIST.indexOf(solution);

  return (
    <CarouselSlider currentIndex={currentSlideIndex}>
      <CarouselSlide>
        <RampSlideContent />
      </CarouselSlide>
      <CarouselSlide>
        <CurrencyConversionSlideContent />
      </CarouselSlide>
      <CarouselSlide>
        <ComplianceSlideContent />
      </CarouselSlide>
    </CarouselSlider>
  );
}
