export const Color = {
  RED: 'red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  PINK: 'pink',
  BLACK: 'black',
} as const;

export type Color = (typeof Color)[keyof typeof Color];

export const ColorToHexMap: Record<Color, string> = {
  [Color.RED]: '#E3424D',
  [Color.ORANGE]: '#FBB15A',
  [Color.YELLOW]: '#FDEC96',
  [Color.GREEN]: '#91D78B',
  [Color.BLUE]: '#2C61F9',
  [Color.PURPLE]: '#B18CD6',
  [Color.PINK]: '#E377CB',
  [Color.BLACK]: '#000000',
};
