import { cn } from '@/common/utils/cn';
import { HTMLMotionProps, motion } from 'framer-motion';
import React, { ForwardedRef, useCallback } from 'react';
import { Color, ColorToHexMap } from './color';

export const COLORS: Color[] = [
  Color.RED,
  Color.ORANGE,
  Color.YELLOW,
  Color.GREEN,
  Color.BLUE,
  Color.PURPLE,
  Color.PINK,
  Color.BLACK,
];

interface ColorPaletteProps
  extends OmitStrict<HTMLMotionProps<'div'>, 'color' | 'onChange'> {
  color: Color;
  onChange: (color: ColorPaletteProps['color']) => void;
}
export const ColorPalette = React.forwardRef(function ColorPalette(
  { className, onChange, ...props }: ColorPaletteProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handleColorChange = useCallback(
    (color: Color) => () => onChange(color),
    [onChange],
  );
  return (
    <motion.div
      ref={ref}
      className={cn(
        'flex gap-x-2.5 px-4 py-4 bg-surface-1 border border-[#E4EBFF] rounded-xl hover:cursor-tailless',
        className,
      )}
      {...props}
    >
      {COLORS.map((color) => (
        <button
          key={color}
          aria-selected={props.color === color}
          className='relative size-[1.375rem] rounded p-1 before:content-[""] before:absolute before:block before:-inset-1 before:rounded before:border before:border-transparent aria-selected:before:border-[#B7CAFF] before:pointer-events-none'
          style={{ backgroundColor: ColorToHexMap[color] }}
          onClick={handleColorChange(color)}
        />
      ))}
    </motion.div>
  );
});
