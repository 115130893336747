import styles from './marquee.module.css';

import React, {
  ElementType,
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';

interface MarqueeProps<T extends ElementType> {
  as?: T;
  children: ReactNode;
  // speed?: number;
  direction?: 'left' | 'right';
  style?: React.CSSProperties;
  className?: string;
}

const MarqueeRoot = forwardRef<Element, MarqueeProps<ElementType>>(
  (
    { as: Component = 'div', children, className, direction, style, ...rest },
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        className={`overflow-hidden whitespace-nowrap ${className}`}
        style={{
          '--animation-marquee-direction':
            direction === 'left' ? 'normal' : 'reverse',
          ...style,
        }}
        {...rest}
      >
        {children}
      </Component>
    );
  },
);

const MarqueeContainer = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...rest }, ref) => {
  return (
    <div ref={ref} className={`overflow-hidden ${className}`} {...rest}>
      {children}
    </div>
  );
});

interface MarqueeContentProps extends React.HTMLAttributes<HTMLDivElement> {
  speed?: number;
  direction?: 'left' | 'right';
}

const ANIMATION_CLASS = 'animate-marquee';

const MarqueeContent = forwardRef<HTMLDivElement, MarqueeContentProps>(
  ({ speed = 5, direction = 'left', className, children, ...rest }, ref) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const previousWidth = useRef<number>();
    const [numClones, setNumClones] = useState(2);

    const ongoing = useRef(false);

    const elementsRef = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(contentRef.current);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLDivElement | null>).current =
            contentRef.current;
        }
      }
    }, [ref]);

    const resetAnimation = useCallback(() => {
      for (const el of elementsRef.current) {
        el.style.animation = 'none';
        el.offsetHeight; // Trigger reflow
        el.style.animation = '';
      }
    }, []);

    // Debounce function to limit the frequency of executing the resize handler
    const debounce = useCallback((func: () => void, wait: number) => {
      let timeout: NodeJS.Timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func();
        }, wait);
      };
    }, []);

    const calculateLayout = useCallback(() => {
      // this check prevents the animation from resetting when only the height of the window changes
      // which happens often in mobile when scrolling
      if (typeof window === 'undefined') return;
      if (previousWidth.current === window.innerWidth) return;
      previousWidth.current = window.innerWidth;

      if (contentRef.current) {
        if (ongoing.current) return;
        ongoing.current = true;
        resetAnimation(); // Reset animation before recalculating layout

        const containerWidth =
          contentRef.current.parentElement?.offsetWidth || 0;
        const contentWidth = contentRef.current.scrollWidth / numClones;
        const requiredClones = Math.max(
          2,
          Math.ceil((containerWidth * 2) / contentWidth),
        );

        if (requiredClones !== numClones) {
          setNumClones(requiredClones);
        }

        const newDuration =
          ((contentWidth * requiredClones) / containerWidth) * speed;

        setTimeout(() => {
          for (const el of elementsRef.current) {
            el.classList.add(styles[ANIMATION_CLASS]);
            el.style.animationDuration = `${newDuration}s`;
          }

          ongoing.current = false;
        }, 50);
      }
    }, [speed, numClones, resetAnimation]);

    useEffect(() => {
      const debouncedCalculateLayout = debounce(calculateLayout, 100);
      calculateLayout();
      window.addEventListener('resize', debouncedCalculateLayout);

      return () => {
        window.removeEventListener('resize', debouncedCalculateLayout);
      };
    }, [calculateLayout, debounce]);

    const createClones = useCallback(() => {
      return Array.from({ length: numClones }).map((_, i) => (
        <div
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={i}
          ref={(el) => {
            if (el) {
              elementsRef.current[i] = el;
            }
          }}
          className='flex'
        >
          {children}
        </div>
      ));
    }, [numClones, children]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: createClones may change
    const clones = useMemo(createClones, [createClones]);

    return (
      <div className={`flex ${className}`} ref={contentRef} {...rest}>
        {clones}
      </div>
    );
  },
);

const MarqueeItem = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...rest }, ref) => {
  return (
    <div className={`mr-4 whitespace-nowrap ${className}`} ref={ref} {...rest}>
      {children}
    </div>
  );
});

export const Marquee = Object.assign(MarqueeRoot, {
  Container: MarqueeContainer,
  Content: MarqueeContent,
  Item: MarqueeItem,
});
