import { SlideDirection } from '@/app/[lang]/(landing)/components/Carousel';
import { Variants } from 'framer-motion';

export const slideContentVariants: Variants = {
  show: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};
export const slideContentItemVariants = {
  hidden: (direction: SlideDirection) => ({
    opacity: 0,
    y: direction === 'up' ? 100 : -100,
  }),
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.5,
      bounce: 0.1,
    },
  },
} as const satisfies Variants;
