import LinkedinWalletsBankIcon from '@landing/assets/solutions/ramp/linked-wallets-bank-icon.svg?url';
import LinkedinWalletsImage from '@landing/assets/solutions/ramp/linked-wallets.png';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function OffloadWalletsSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      custom={direction}
      variants={slideContentVariants}
      initial='hidden'
      animate='show'
      className='relative w-full px-12 py-12 md:p-0'
    >
      <div className='relative mx-auto min-w-[256px] w-[66.82389937%] max-w-[425px]'>
        <MotionImage
          custom={direction}
          variants={slideContentItemVariants}
          src={LinkedinWalletsImage}
          alt=''
          className='w-full '
        />
        <MotionImage
          custom={direction}
          variants={slideContentItemVariants}
          src={LinkedinWalletsBankIcon}
          alt=''
          className='absolute max-w-[152px] w-[35.76470588%] -right-[13.17647059%] -bottom-[6.65053297%] max-md:hidden'
        />
      </div>
    </motion.div>
  );
}
