import usePrevious from '@/common/hooks/usePrevious';
import { cn } from '@/common/utils/cn';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import React from 'react';
import { CarouselProvider } from './CarouselProvider';
import { SlideDirection } from './types';

const slideVariants: Variants = {
  enter: (direction: SlideDirection) => ({
    y: direction === 'up' ? 100 : -100,
    opacity: 0,
  }),
  center: {
    y: 0,
    opacity: 1,
  },
  exit: (direction: SlideDirection) => ({
    y: direction === 'up' ? -100 : 100,
    opacity: 0,
  }),
};

interface CarouselSliderProps {
  children: React.ReactNode;
  currentIndex: number;
  className?: string;
}

export function CarouselSlider({
  children,
  currentIndex,
  className = '',
}: CarouselSliderProps) {
  const previousIndex = usePrevious(currentIndex);

  const direction: SlideDirection =
    previousIndex === undefined || currentIndex > previousIndex ? 'up' : 'down';

  return (
    <div className={cn('relative w-full', className)}>
      <CarouselProvider direction={direction}>
        <AnimatePresence custom={direction} initial={false}>
          <motion.div
            key={currentIndex}
            custom={direction}
            variants={slideVariants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              duration: 0.5,
              bounce: 0.1,
              type: 'spring',
            }}
            className='absolute w-full'
          >
            {React.Children.toArray(children)[currentIndex]}
          </motion.div>
        </AnimatePresence>
      </CarouselProvider>
    </div>
  );
}
