import GrossVolumeImage from '@landing/assets/solutions/dashboard/gross-volume.png';
import MultipleChartsImage from '@landing/assets/solutions/dashboard/multiple-charts.png';

import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';

import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function AnalyticsSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      className='relative w-full md:aspect-[708/667] max-md:pb-6 md:mt-8 [mask-image:_linear-gradient(to_right,#000_0%,#000_90%,transparent_100%)]'
      initial='hidden'
      animate='show'
      variants={slideContentVariants}
      custom={direction}
    >
      <div className='w-[122%] aspect-[865/508] min-w-[865px] max-w-[865px] mx-auto relative '>
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={MultipleChartsImage}
          alt=''
          className='absolute w-[87%] top-[31.7%] left-[3.7%] md:left-[8.7%] lg:left-[13.7%] max-w-[755px] drop-shadow-glow'
        />
        <MotionImage
          variants={slideContentItemVariants}
          custom={direction}
          src={GrossVolumeImage}
          alt=''
          className='relative z-10 w-full max-w-[865px] md:left-[5%] lg:left-[10%] drop-shadow-glow'
        />
      </div>
    </motion.div>
  );
}
