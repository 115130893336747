import PaperAirplaneImage from '@landing/assets/solutions/ramp/paper-airplane.png';
import SphereTelegramBotPhoneImage from '@landing/assets/solutions/ramp/sphere-telegram-bot-phone.png';
import SphereTelegramBotPfpIcon from '@landing/assets/solutions/ramp/sphere-telegram-bot-profile-photo.svg?url';
import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function TelegramBotSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      custom={direction}
      variants={slideContentVariants}
      initial='hidden'
      animate='show'
      className='relative p-4 md:p-0'
    >
      <div className='isolate max-w-[619px] relative mx-auto md:-top-12'>
        <MotionImage
          src={SphereTelegramBotPhoneImage}
          alt=''
          className='w-full'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <MotionImage
          src={SphereTelegramBotPfpIcon}
          alt=''
          className='absolute max-w-[134px] w-[21.64781906%] top-[12.27786753%] left-[20.51696284%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <MotionImage
          src={PaperAirplaneImage}
          alt=''
          className='absolute max-w-[127px] w-[20.51696284%] right-[23.26332795%] top-[50.56542811%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <div className='absolute aspect-square w-[70.4361874%] inset-0 bg-[#EEF2FF] m-auto rounded-full -z-10' />
      </div>
    </motion.div>
  );
}
