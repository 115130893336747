import KycSuccessImage from '@landing/assets/solutions/ramp/kyc-success.png';
import OnboardingKycImage from '@landing/assets/solutions/ramp/onboarding-kyc.png';
import GeolocationImage from '@landing/assets/solutions/ramp/geolocation.png';

import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { motion } from 'framer-motion';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function ComplianceSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      custom={direction}
      variants={slideContentVariants}
      initial='hidden'
      animate='show'
      className='relative'
    >
      <div className='isolate max-w-[664px] w-full aspect-square relative mx-auto md:-top-12 md:min-w-[560px] *:drop-shadow-glow'>
        <MotionImage
          src={OnboardingKycImage}
          alt=''
          className='absolute max-w-[384px] w-[70%] bottom-[10%] left-1/2 !-translate-x-1/2 md:w-[57.8313253%] md:bottom-[15%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <MotionImage
          src={KycSuccessImage}
          alt=''
          className='absolute z-20 max-w-[302px] w-[60%] top-[16.5%] max-md:right-[5%] md:w-[45.62801205%] md:left-[42.6%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
        <MotionImage
          src={GeolocationImage}
          alt=''
          className='absolute z-30 max-w-[322px] w-[55%] top-[10%] left-[5%] md:w-[48.45180723%] md:left-[7.37951807%] md:top-[13.10240964%]'
          custom={direction}
          variants={slideContentItemVariants}
        />
      </div>
    </motion.div>
  );
}
