import React, { createContext, useContext } from 'react';
import { SlideDirection } from './types';

interface CarouselContextType {
  direction: SlideDirection;
}

const CarouselContext = createContext<CarouselContextType>({ direction: 'up' });

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  return context;
};

export const CarouselProvider: React.FC<
  CarouselContextType & { children: React.ReactNode }
> = ({ children, direction }) => {
  return (
    <CarouselContext.Provider value={{ direction }}>
      {children}
    </CarouselContext.Provider>
  );
};
