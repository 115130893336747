import { useCarouselContext } from '@/app/[lang]/(landing)/components/Carousel/CarouselProvider';
import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import BrazilFlagIcon from '@landing/assets/bra-flag-rounded-white-border.svg?url';
import ChileFlagIcon from '@landing/assets/chile-flag-rounded.svg?url';
import EuroFlagIcon from '@landing/assets/eu-flag-rounded.svg?url';
import CurrencyConversionImage from '@landing/assets/solutions/ramp/currency-conversion.png';
import { MotionDiv } from '@landing/components/Motion';
import { motion } from 'framer-motion';
import Image from 'next/image';
import {
  slideContentItemVariants,
  slideContentVariants,
} from '../../slideContentAnimation';

export default function CurrencyConversionSlideContent() {
  const { direction } = useCarouselContext();
  return (
    <motion.div
      custom={direction}
      variants={slideContentVariants}
      initial='hidden'
      animate='show'
      className='relative w-full px-8 pr-7 pb-8 pt-8 md:p-0 md:mt-[8%] md:absolute md:left-0'
    >
      <div className='relative flex flex-col w-full aspect-[474/477] z-0 mx-auto min-w-[474px] max-w-[474px] max-md:min-w-[256px]'>
        <MotionImage
          custom={direction}
          variants={slideContentItemVariants}
          src={CurrencyConversionImage}
          alt=''
          className='w-[83.33333333%] mx-auto shadow-glow rounded-xl my-auto'
        />
        <MotionDiv
          custom={direction}
          variants={slideContentItemVariants}
          className='absolute flex items-center justify-center max-w-[76px] w-[16.03375527%] aspect-square  bg-surface-1 border border-outline shadow-glow rounded-lg right-0 bottom-[51%]'
        >
          <Image src={BrazilFlagIcon} alt='' className='w-[73%] flex-none' />
        </MotionDiv>
        <MotionDiv
          custom={direction}
          variants={slideContentItemVariants}
          className='absolute flex items-center justify-center max-w-[89px] w-[22.53164557%] aspect-square  bg-surface-1 border border-outline shadow-glow rounded-lg left-0 bottom-0'
        >
          <Image src={ChileFlagIcon} alt='' className='w-[73%] flex-none' />
        </MotionDiv>
        <MotionDiv
          custom={direction}
          variants={slideContentItemVariants}
          className='absolute flex items-center justify-center max-w-[76px] w-[16.03375527%] aspect-square  bg-surface-1 border border-outline shadow-glow rounded-lg left-0 top-0 -z-10'
        >
          <Image src={EuroFlagIcon} alt='' className='w-[73%] flex-none' />
        </MotionDiv>
      </div>
    </motion.div>
  );
}
