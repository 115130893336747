import React from 'react';
import { motion } from 'framer-motion';

export const PixelAlien = React.forwardRef(function PixelAlien(
  props: React.ComponentPropsWithoutRef<typeof motion.svg>,
  ref: React.ForwardedRef<SVGSVGElement>,
) {
  return (
    <motion.svg
      ref={ref}
      width={96}
      height={94}
      fill='none'
      className='overflow-visible'
      {...props}
    >
      <motion.path
        fill='#fff'
        stroke='#2C61F9'
        d='M88.5 86.5h7v7h-7zm-88 0h7v7h-7zm88-86h7v7h-7zM.5.5h7v7h-7z'
      />

      <motion.path
        stroke='#2C61F9'
        strokeDasharray='2 2'
        d='M3.5 3.5h89v87h-89z'
      />
      <motion.path
        d='M54.062 61.601V81h5.845V61.601h5.565v5.878h12.334v-6.121h-6.769V55.64h11.97V45.239h-5.201V49.4H65.759v-4.162h12.047v-12.04H71.8v5.801h-6.083V22.2h-5.845V13H36.058v9.2h-5.845v16.758H24.13v-5.802h-6.013v12.041h12.054v4.162h-11.97v-4.162H13v10.401h11.97v5.718h-6.769v6.121h12.334V61.56H36.1v19.398h5.845V61.56h12.173l-.042.034-.014.007zm-17.976-6.6H30.85v-3.523h5.236v3.523zm7.861-20.24h-4.914V29.6h4.914v5.162zm13.055 0h-4.914V29.6h4.914v5.162zm8.141 20.24h-5.236v-3.523h5.236v3.523z'
        className='z-20 animate-float'
        fill='#000'
      />
    </motion.svg>
  );
});
