import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import { Variants, motion } from 'framer-motion';
import EmailNotificationImage from '@landing/assets/features/email-notifications/email-notification.png';
import MailAppIcon from '@landing/assets/features/email-notifications/mail-app-icon.png';
import { cn } from '@/common/utils/cn';

export const containerVariants: Variants = {
  hidden: {
    y: 100,
  },
  show: {
    y: 0,
    transition: {
      staggerChildren: 0.4,
    },
  },
};

export const childVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.5,
      bounce: 0.1,
    },
  },
};
const iconVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,

    transition: {
      type: 'spring',
      duration: 0.5,
      bounce: 0.25,
    },
  },
};

export function EmailNotifications({ className }: { className?: string }) {
  return (
    <motion.div
      variants={containerVariants}
      initial='hidden'
      animate='show'
      className={cn('absolute -left-[37.8%] top-[25%] w-[123.20%]', className)}
    >
      <MotionImage
        variants={childVariants}
        src={EmailNotificationImage}
        alt=''
        className='w-full h-auto'
      />
      <MotionImage
        variants={iconVariants}
        src={MailAppIcon}
        alt=''
        className='absolute -right-[6%] -top-[10%] w-[14%] h-auto'
      />
    </motion.div>
  );
}
