'use client';

import AccessControlListImage from '@landing/assets/features/access-control-list/access-control-list.png';
import AnalyticsChart1 from '@landing/assets/features/analytics/analytics-chart-1.png';
import AnalyticsChart2 from '@landing/assets/features/analytics/analytics-chart-2.png';

import { MotionImage } from '@/app/[lang]/(landing)/components/MotionImage';
import Image from 'next/image';

import { useTranslation } from 'react-i18next';
import { EmailNotifications } from '../components/EmailNotifications';
import { Grid } from '../components/Grid';
import { MultipartyPayments } from '../components/MultipartyPayments';
import { PaymentBlocked } from '../components/PaymentBlocked';
import { SecurityMarquee } from '../components/SecurityMarquee';

export function FeaturesGrid() {
  const { t } = useTranslation();

  return (
    <Grid className='text-default text-base min-[540px]:max-md:text-xl leading-5 font-normal flex-col md:flex-row max-lg:flex-wrap mt-8  md:mt-12'>
      <Grid.Column className='md:*:grow basis-full lg:basis-[445px] max-md:contents max-lg:flex-row'>
        <Grid.Card className='aspect-[445/339]'>
          <Grid.CardTitle>{t('features.grid.0')}</Grid.CardTitle>
          <MotionImage
            src={AnalyticsChart1}
            alt=''
            className='max-w-[560px] w-[128.68829856%] absolute z-20 left-[22%] -top-[10%] drop-shadow-glow'
            whileInView={{
              y: [0, 15],
              transition: {
                repeat: Number.POSITIVE_INFINITY,
                repeatType: 'mirror',
                ease: 'easeInOut',
                duration: 5,
              },
            }}
          />
          <MotionImage
            src={AnalyticsChart2}
            alt=''
            className='max-w-[645.5px] w-[148.33624414%] absolute z-10 left-[8%] top-[5%]'
          />
        </Grid.Card>
        <Grid.Card className='aspect-[445/301] px-0'>
          <Grid.CardTitle className='px-5'>
            {t('features.grid.1')}
          </Grid.CardTitle>
          <MultipartyPayments />
        </Grid.Card>
      </Grid.Column>
      <Grid.Column className='md:*:grow basis-full lg:basis-[370.64px] max-md:contents max-lg:flex-row'>
        <Grid.Card className='w-full aspect-[370.64/363]'>
          <Grid.CardTitle>{t('features.grid.2')}</Grid.CardTitle>
          <Image
            src={AccessControlListImage}
            alt=''
            className='w-[96%] h-auto object-contain absolute bottom-0 right-0'
          />
        </Grid.Card>
        <Grid.Card className='w-full aspect-[370.64/276.34]'>
          <Grid.CardTitle>{t('features.grid.3')}</Grid.CardTitle>
          <PaymentBlocked className='w-full h-full absolute inset-x-0 -bottom-[25%]' />
        </Grid.Card>
      </Grid.Column>
      <Grid.Column className='flex md:min-w-0 basis-full lg:basis-[370.64px] lg:*:grow max-md:contents max-lg:flex-row max-lg:*:flex-1 '>
        <Grid.Card className='flex flex-col w-full min-w-0 px-0 pb-0 aspect-[370.64/338.21]'>
          <Grid.CardTitle className='px-5'>
            {t('features.grid.4')}
          </Grid.CardTitle>
          <SecurityMarquee />
        </Grid.Card>
        <Grid.Card className='aspect-[370.64/301.14]'>
          <Grid.CardTitle>{t('features.grid.5')}</Grid.CardTitle>
          <EmailNotifications />
        </Grid.Card>
      </Grid.Column>
    </Grid>
  );
}
