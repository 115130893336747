'use client';
import { AppBadge } from '@/common/components/AppBadge';
import { cn } from '@/common/utils/cn';

import {
  CarouselSlide,
  CarouselSlider,
} from '@/app/[lang]/(landing)/components/Carousel';
import { useIsMobile } from '@/app/[lang]/(landing)/providers/MobileProvider';
import { Sofia_Sans_Condensed } from 'next/font/google';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SolutionsAccordion } from '../components/SolutionsAccordion';
import {
  AnalyticsSlideContent,
  DeveloperToolkitSlideContent,
  PaymentLinksSlideContent,
  SubscriptionsSlideContent,
} from './slides';

const sofiaSansCondensed = Sofia_Sans_Condensed({
  subsets: ['latin'],
  weight: 'variable',
});

const DashboardSolution = {
  PAYMENT_LINKS: 'paymentLinks',
  SUBSCRIPTIONS: 'subscriptions',
  DEVELOPER_TOOLKIT: 'developerToolkit',
  ANALYTICS: 'analytics',
} as const;

type DashboardSolution =
  (typeof DashboardSolution)[keyof typeof DashboardSolution];

const DASHBOARD_SOLUTIONS_LIST = [
  DashboardSolution.PAYMENT_LINKS,
  DashboardSolution.SUBSCRIPTIONS,
  // DashboardSolution.INVOICES,
  DashboardSolution.DEVELOPER_TOOLKIT,
  DashboardSolution.ANALYTICS,
];

export function DashboardSolutions() {
  const isMobile = useIsMobile();
  const [solution, setSolution] = useState<DashboardSolution>('paymentLinks');
  const { t } = useTranslation();

  const handleSolutionChange = useCallback((solution: string) => {
    if (!DASHBOARD_SOLUTIONS_LIST.includes(solution as DashboardSolution))
      return;

    setSolution(solution as DashboardSolution);
  }, []);

  return (
    <div className='relative bg-background-dim overflow-hidden z-30'>
      <div className='relative flex h-fit w-full flex-col items-center justify-center mx-auto pt-24 pb-16 max-md:max-w-[min(var(--page-width),calc(100%_-_1.5rem))]'>
        <div className={cn('w-full grid md:grid-cols-2 auto-rows-fr gap-x-12')}>
          {!isMobile && <DesktopCarousel solution={solution} />}

          <div className='min-w-0 flex flex-col gap-y-8 md:pr-6 md:max-w-[calc(var(--page-width)_/_2)] bg-background-dim z-10'>
            <div className='flex flex-col gap-y-3 md:pl-4'>
              <AppBadge
                color='primary'
                className='self-start py-1 text-sm leading-[1.125rem]'
              >
                {t('dashboard.topBadge')}
              </AppBadge>
              <h3
                className={cn(
                  sofiaSansCondensed.className,
                  'font-[800]',
                  'text-[3.625rem] uppercase leading-[3.5rem]',
                )}
              >
                {t('dashboard.header')}
              </h3>
              <p className='text-subtle font-light leading-7'>
                {t('dashboard.subHeading')}
              </p>
            </div>
            <SolutionsAccordion
              value={solution}
              onValueChange={handleSolutionChange}
              className='min-h-[32rem] [&[data-state="open"]+[data-state="open"]]:*:mt-4 *:transition-[margin]'
            >
              <SolutionsAccordion.Item
                badgeNumber={1}
                title={t('dashboard.solutions.0.title')}
                value={DashboardSolution.PAYMENT_LINKS}
                className='transition-colors data-[state=open]:bg-surface-1 hover:data-[state=closed]:bg-background-dim-hover rounded-lg py-4 max-md:px-4'
              >
                <p>{t('dashboard.solutions.0.description')}</p>
                <AccordionContentRenderer
                  solution={DashboardSolution.PAYMENT_LINKS}
                  isMobile={isMobile}
                />
              </SolutionsAccordion.Item>

              <SolutionsAccordion.Item
                badgeNumber={2}
                title={t('dashboard.solutions.1.title')}
                value={DashboardSolution.SUBSCRIPTIONS}
                className='transition-colors data-[state=open]:bg-surface-1 hover:data-[state=closed]:bg-background-dim-hover rounded-lg py-4 max-md:px-4'
              >
                <p>{t('dashboard.solutions.1.description')}</p>
                <AccordionContentRenderer
                  solution={DashboardSolution.SUBSCRIPTIONS}
                  isMobile={isMobile}
                />
              </SolutionsAccordion.Item>

              <SolutionsAccordion.Item
                title={t('dashboard.solutions.2.title')}
                badgeNumber={3}
                value={DashboardSolution.DEVELOPER_TOOLKIT}
                className='transition-colors data-[state=open]:bg-surface-1 hover:data-[state=closed]:bg-background-dim-hover rounded-lg py-4 max-md:px-4'
              >
                <p>{t('dashboard.solutions.2.description')}</p>
                <AccordionContentRenderer
                  solution={DashboardSolution.DEVELOPER_TOOLKIT}
                  isMobile={isMobile}
                />
              </SolutionsAccordion.Item>
              <SolutionsAccordion.Item
                title={t('dashboard.solutions.3.title')}
                badgeNumber={4}
                value={DashboardSolution.ANALYTICS}
                className='transition-colors data-[state=open]:bg-surface-1 hover:data-[state=closed]:bg-background-dim-hover rounded-lg py-4 max-md:px-4'
              >
                <p>{t('dashboard.solutions.3.description')}</p>
                <AccordionContentRenderer
                  solution={DashboardSolution.ANALYTICS}
                  isMobile={isMobile}
                />
              </SolutionsAccordion.Item>
            </SolutionsAccordion>
          </div>
        </div>
      </div>
    </div>
  );
}

function AccordionContentRenderer({
  solution,
  isMobile,
}: {
  solution: DashboardSolution;
  isMobile: boolean;
}) {
  if (!isMobile) return null;

  const content = useMemo(() => {
    switch (solution) {
      case DashboardSolution.PAYMENT_LINKS:
        return <PaymentLinksSlideContent />;
      case DashboardSolution.SUBSCRIPTIONS:
        return <SubscriptionsSlideContent />;
      case DashboardSolution.DEVELOPER_TOOLKIT:
        return <DeveloperToolkitSlideContent />;
      case DashboardSolution.ANALYTICS:
        return <AnalyticsSlideContent />;
      default:
        return null;
    }
  }, [solution]);

  return <div className='w-full mt-8'>{content}</div>;
}

function DesktopCarousel({ solution }: { solution: DashboardSolution }) {
  const currentSlideIndex = DASHBOARD_SOLUTIONS_LIST.indexOf(solution);

  return (
    <CarouselSlider currentIndex={currentSlideIndex} className='w-auto '>
      <CarouselSlide>
        <PaymentLinksSlideContent />
      </CarouselSlide>
      <CarouselSlide>
        <SubscriptionsSlideContent />
      </CarouselSlide>
      <CarouselSlide>
        <DeveloperToolkitSlideContent />
      </CarouselSlide>
      <CarouselSlide>
        <AnalyticsSlideContent />
      </CarouselSlide>
    </CarouselSlider>
  );
}
